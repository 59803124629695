<template>
  <div class="border-top-primary border-3 border-table-radius px-0">
    <div>

      <div
        v-for="(bankAccount,index) in bankAccounts"

        :key="index"
      >
        <div>

          <b-card-group deck>

            <div class="content_information">
              <div class="information">
                <div class="title_information">
                  Account type:
                </div>
                <div v-if="bankAccount.currency===2">

                  <ul><li class="title_content">
                    Dolares ({{ bankAccount.currency_text }})
                  </li></ul>
                </div>
                <div v-if="bankAccount.currency===1">
                  <ul><li class="title_content">
                    Soles ({{ bankAccount.currency_text }})
                  </li></ul>
                </div>
                <div class="title_information">
                  Account name:
                </div>
                <div>    <ul><li class="title_content">
                  {{ bankAccount.bank_name }}
                </li></ul> </div>
              </div>
            </div>

            <b-card class="card_dashboard"
          >

              <div

                style="height: 100px"
              >
                <div
                  class="content_icon_initial_balance"
                >

                  <amg-icon

                    style="margin-left: 7px; color: white"
                    size="50"
                    icon="InitialBalanceIcon"
                  />

                </div>
                <div class="content_title text-secondary">
                  Initial Balance
                </div>
                <div class="content_card_information">
                  {{ bankAccount.currency_text }}
                  {{ formatPrice(bankAccount.balance) }}
                </div>
              </div>
            </b-card>
            <feather-icon
              class="icons_dash"

              size="23"
              icon="PlusIcon"
            />
            <b-card class="card_dashboard cursor-pointer "  @click="incomesRouter">
              <div

                style="height: 100px"
              >
                <div class="content_icon_incomes">

                  <amg-icon
                    class="icon_blue_especial"
                    style="color: white"
                    icon="IncomesIcon"
                    size="45"
                  />
                </div>

                <div class="content_title text-secondary">
                  Incomes
                </div>
                <div class="content_card_information">
                  {{ bankAccount.currency_text }}
                  {{ formatPrice(bankAccount.total_incomes) }}
                </div>
              </div>

            </b-card>
            <feather-icon
              class="icons_dash"
              size="23"
              icon="MinusIcon"
            />
            <b-card class="card_dashboard cursor-pointer"  @click="expensesRouter">
              <div

                style="height: 100px"
              >
                <div class="content_icon_expenses">

                  <amg-icon
                    style="position: absolute; top: 7%; left: 27%; color: white"
                    size="45"
                    icon="IncomesIcon"
                  />

                </div>
                <div class="content_title text-secondary">
                  Expenses
                </div>
                <div class="content_card_information">
                  {{ bankAccount.currency_text }}
                  {{ formatPrice(bankAccount.total_expenses) }}
                </div>
              </div>

            </b-card>
            <tabler-icon
              class="icons_dash"
              size="23"
              icon="MenuIcon"
            />
            <b-card class="card_dashboard ">
              <div

                style="height: 100px"
              >
                <div class="content_icon_total_balance">

                  <amg-icon
                    style="color: white"
                    class="icon_balance_especial"
                    size="45"
                    icon="BalanceIcon"
                  />

                </div>

                <div class="content_title text-secondary">
                  Balance
                </div>
                <div class="content_card_information">
                  {{ bankAccount.currency_text }}
                  {{ formatPrice(bankAccount.total_balance) }}
                </div>
              </div>
            </b-card></b-card-group>

        </div>

      </div>

      <div v-if="bankAccountes">
        <router-link
            target="_blank"
          class="d-flex justify-content-center mt-3"
          style="font-size: 1.6rem; margin-top: 10px"
          :to="{name: 'bankAccount-company'}"
        >
          There are no bank accounts currently. Please register at least one.
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>

import CompaniesService from '@/views/management/views/companies/companies.service'
import { mapGetters } from 'vuex'

export default {

  data() {
    return {
      dashboardData: [],
      bankAccounts: [],
      bankAccountes: false,

    }
  },

  async mounted() {
    this.addPreloader()

    await this.getBankAccountAll()
    this.removePreloader()
  },

  methods: {
    incomesRouter(){
      this.$router.push({ name: 'incomes-company' })
    },
    expensesRouter(){
      this.$router.push({ name: 'category-expenses' })
    },
    calculateNewBalance(balance, incomes, expenses) {
      return Number(balance) + Number(incomes) - Number(expenses)
    },
    async getDashBoardData() {
      try {
        const params = {
          company_id: this.$route.params.id,
        }
        const data = await CompaniesService.getDashboardData(params)

        this.dashboardData = data.data

        return this.dashboardData
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getBankAccountAll() {
      this.addPreloader()
      try {
        const params = {
          idcompany: Number(this.$route.params.id),
        }
        const data = await CompaniesService.getBankAccountsAll(params)

        this.bankAccounts = data.data
        if (!this.bankAccounts[0]) {
          this.bankAccountes = true
        }
        for (let i = 0; i < this.bankAccounts.length; i++) {
          this.bankAccounts[i].total_balance = this.bankAccounts[i].total_balance === null ? this.bankAccounts[i].total_balance = 0 : this.bankAccounts[i].total_balance
          this.bankAccounts[i].total_expenses = this.bankAccounts[i].total_expenses === null ? this.bankAccounts[i].total_expenses = 0 : this.bankAccounts[i].total_expenses
          this.bankAccounts[i].total_incomes = this.bankAccounts[i].total_incomes === null ? this.bankAccounts[i].total_incomes = 0 : this.bankAccounts[i].total_incomes
          this.bankAccounts[i].balance = this.bankAccounts[i].balance === null ? this.bankAccounts[i].balance = 0 : this.bankAccounts[i].balance
          this.bankAccounts[i].total_balance = this.calculateNewBalance(this.bankAccounts[i].balance, this.bankAccounts[i].total_incomes, this.bankAccounts[i].total_expenses)
        }
        // Must return an array of items or an empty array if an error occurred
        this.removePreloader()
        return this.bankAccounts
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>
.icon_balance_especial{
  position: absolute;
  top: 11%;
  left: 18%;
}
.card_dashboard {
  display: block;
  max-width: 200px !important;
  height: 223px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(240, 242, 247, 0.15);
  border-radius: 26px;
  margin-top: 30px;
}
.content_information{
  width: 27%;
  height: 100px;
}

.information{

  margin-left: 50px;
  margin-top: 70px;
}

.title_information{
  font-weight: 600;
  font-size: large;
  margin-bottom: 10px;
}

.content_icon_initial_balance{
padding: 10px;

  width: 67px;
  height: 67px;

  background: linear-gradient(180deg, #00D2C4 0%, #00E8E0 100%);
  box-shadow: 0px 4px 10px rgba(0, 232, 224, 0.25);
  border-radius: 14px;

}
.content_icon_total_balance{
  padding: 10px;

  width: 67px;
  height: 67px;

  background: linear-gradient(180deg, #006AFF 0%, #0093FF 100%);
  box-shadow: 0px 4px 10px rgba(0, 147, 255, 0.25);
  border-radius: 14px;

}
.content_icon_expenses{
  padding: 10px;

  width: 67px;
  height: 67px;

  background: linear-gradient(180deg, #F0874E 0%, #F8B881 100%);
  box-shadow: 0px 4px 10px rgba(248, 184, 129, 0.25);
  border-radius: 14px;
  transform: rotate(-180deg);

}
.content_icon_incomes{
  padding: 10px;

  width: 67px;
  height: 67px;

  background: linear-gradient(180deg, #4E73F1 0%, #81A8F8 100%);
  box-shadow: 0px 4px 10px rgba(129, 168, 248, 0.25);
  border-radius: 14px;

}

.content_title{
  margin-top: 18px;
  font-size: 18px;
  font-weight: 600;
}

.content_card_information{
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
}

.icons_dash{
  margin-top: 130px;
  margin-left: 30px;
  margin-right: 30px;
}
.icon_blue_especial{
  position: absolute;
  top: 10%;
  left: 20%;
}
@media (max-width: 1520px) {

  .information{

    margin-left: 30px;
    margin-top: 50px;
  }

  .content_information{
    width: 18%;
    height: 100px;
  }
  .card_dashboard {

    width: 10000px !important;
    height: 170px;

  }
  .content_card_information{

    font-size: 20px;

  }

  .content_title{
    margin-top: 18px;
    font-size: 12px;
    font-weight: 600;
  }
  .content_card_information{
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
  }
  .icons_dash{
    margin-top: 110px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .content_icon{
    padding: 10px;
    background: black;
    width: 57px;
    height: 57px;
    border-radius: 18px;
  }
  .title_information{
    font-weight: 600;
    font-size: small;
    margin-bottom: 0.5rem;
  }
  .title_content{
    font-size: 12px;
  }
  .icon_blue_especial{
    position: absolute;
    top: 13%;
    left: 23%;
  }

  .icon_balance_especial{
    position: absolute;
    top: 15%;
    left: 21%;
  }
}

</style>
